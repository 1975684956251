import React from "react"
import { Link, graphql } from "gatsby"
import Page from "../components/page"
import BlogPostsGrid from "../components/blog-posts-grid"
import { SectionLarge } from "../components/elements"

const PagesComponent = props => {
  const { numberOfPages } = props
  if (numberOfPages <= 1) {
    return null
  }
  return (
    <div
      className="blog__more-pages"
      style={{
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {[...Array(numberOfPages).keys()].map(n => {
        const destination = `/blog${n === 0 ? "" : "/" + (n + 1)}`
        return (
          <Link
            className="blog__page-link"
            activeClassName="active"
            to={destination}
            key={n + 1}
          >
            <span>{n + 1}</span>
          </Link>
        )
      })}
    </div>
  )
}

export default function BlogIndex(props) {
  const { data, pageContext } = props
  const { blogPosts } = data.blogPostsData
  const { numberOfPages } = pageContext
  return (
    <Page title="Blog">
      <SectionLarge>
        <BlogPostsGrid posts={blogPosts} />
        <PagesComponent numberOfPages={numberOfPages} />
      </SectionLarge>
    </Page>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery($skip: Int!, $limit: Int!) {
    blogPostsData: allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { fields: { sourceInstanceName: { eq: "blog" } } }
      limit: $limit
      skip: $skip
    ) {
      ...BlogPosts
    }
  }
`
